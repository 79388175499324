export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'NexGen Sports Scheduling',
  siteURL: 'https://nexgenathl.com/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/nexgen_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#ffa500',
    mobileSignInColor: '#ffa500',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#ffa500',
    accent1Color: '#000',

    primary: {
      main: '#ffa500',
    },
    secondary: {
      main: '#000',
    },
  },
  sideNav: {
    selectedColor: '#ffa500',
  },
  icons: {
    cart: '#ffa500',
  },
  statusBar: {
    backgroundColor: '#ffa500',
  },
};
